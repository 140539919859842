
$primary-color: #009016;
$primary-color-alt: #33cd4b;
$status-color: #CC2B48;

$sans-serif-font: 'Source Sans Pro', Helvetica, Arial, sans-serif;
$serif-font: 'Zilla Slab', Georgia, serif;

$fonturl: $baseurl + "/assets/fonts/";

@font-face{
  src: url($fonturl + 'NationalPark-ExtraBold.otf');
  font-family: "National Park";
  font-weight: 800;
}
@font-face{
  src: url($fonturl + 'NationalPark-Bold.otf');
  font-family: "National Park";
  font-weight: 700;
}

@font-face{
  src: url($fonturl + 'BostonAngel-Regular.otf');
  font-family: "Boston Angel";
  font-weight: 400;
}

@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&family=Zilla+Slab:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap');
  