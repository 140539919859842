.homepage {
  .app-gallery {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    left: -$sp*0.5;
    width: calc(100% + $sp);

    .app {
      position: relative;
      text-align: center;
      width: calc(($content-width + $sp)/3);
      padding: 0 $sp*0.5 $sp $sp*0.5;
    }
    .app-sign {
      position: relative;
      transition: transform 100ms ease-in-out;
      &:hover {
        transform: scale(1.04);
        z-index: 9999;
      }
      margin-bottom: $sp*0.25;
    }
    .tagline {
      font-weight: 600;
    }
  }

  .map-section {
    background-color: #F9F5F1;
    border-top: 1px solid #e5e2de;
    border-bottom: 1px solid #e5e2de;
  }
  .map-container {
    padding-left: $container-padding*2;
    padding-right: $container-padding*2;
    margin: 0 auto;

    @media only screen and (max-width: $container-width) {
      padding-left: $container-padding;
      padding-right: $container-padding;
    }
  }
  iframe.mapframe {
    width: 100%;
    height: 75vh;
    border: 1px solid #e5e2de;
  }

  @media only screen and (max-width: $sections-width) {
    .app-gallery {
      justify-content: center;
    }
    .section-header {
      text-align: center;
      justify-content: center;
    }
  }

  .splash {
    width: 100%;
    background-position: center top;
    background-size: cover;
    padding-top: 25vw;
  }

  .splash-body {
    border-top: 1px solid $alpha-grid-color;
    text-align: center;
    color: white;
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    img.seal {
      margin-top: -80px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: $sp;
    }
    background: rgba(2, 45, 77, 0.94);
    padding-bottom: $sp;
  }
}

.ispage-our-work-community-charter-projects {
  .cover {
    background: linear-gradient(to bottom, #CE4D00, #DB7C01);
    .logo-area {
      flex-direction: column;
      .logo-wrap {
        width: 140px;
      }
    }
  }
}

.page-our-work-dogwood {
  .cover {
    background: linear-gradient(328deg, #eeb3ce 12.61%, #fff 90.34%);
    color: #333;

    a {
      color: #753b55;
    }
    h1 {
      font-family: Georgia, serif;
    }
  }
  a.button.bordered {
    color: #753b55;
    border-color:  #753b55;
  }
  a.button.prominent {
    background-color: #753b55;
    border-color:  #753b55;
    color: #fff;
  }
}
.page-our-work-public-domain-map {
  .themed {
    background: linear-gradient(to right, #1D1C42, #3D3B77);
  }
  a.button.prominent {
    background-color: #5451C5;
    border-color: #5451C5;
  }
}
.page-get-involved-slack {
  .cover {
    background: linear-gradient(to right, #4A154B, #824484);
  }
  a.button.prominent {
    background-color: #b059b4;
    border-color: #b059b4;
  }
}
.page-our-work-field-papers {
  .cover {
    background: black;
  }
  a.button.prominent {
    background-color: white;
    border-color: white;
    color: black;
  }
}
.page-our-work-community-projects-americana {
  h1 {
    font-family: $sans-serif-font;
    font-weight: 700;
  }
  .themed {
    background: #F9F5F1;

    color: #333;

    a {
      color: #003F87;
    }

    .extra-99 {
      height: 430px;
      width: 100%;
      background-size: 2200px;
      background-color: #F9F5F1;
      background-image: url($baseurl + "/img/pages/americana/bg.jpg");
      background-position: center bottom;
      background-repeat: no-repeat;
    }

    &.banner-footer {
      background-position: center 56%;
      height: 70px;
    }
  }
  .layout-body {
    a.button.bordered {
      border-color: #003F87;
      color: #003F87;
    }   
    a.button.prominent {
      background-color: #003F87;
      border-color: #003F87;
      color: white;
    }
  }
}
.ispage-our-work-community-projects-americana {
  .cover {
    .pagenav {
      border-bottom-color: $alpha-grid-color;
    }
  }
  .map-container-wrap {
    filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.25));
  }
  .map-container, .mapframe {
    clip-path: polygon(0% 0%, 25% 20px, 50% 0%, 75% 20px, 100% 0%, calc(100% - 20px) 25%, 100% 50%, calc(100% - 20px) 75%, 100% 100%, 75% calc(100% - 20px), 50% 100%, 25% calc(100% - 20px), 0% 100%, 20px 75%, 0% 50%, 20px 25%);
    /*@media only screen and (max-width: $sections-width) and (max-height: $sections-width) {
      clip-path: polygon(0% 0%, 50% 20px, 100% 0%, calc(100% - 20px) 50%, 100% 100%, 50% calc(100% - 20px), 0% 100%, 20px 50%);
    }*/
  }
  .map-container {
    margin-top: -$hero-overlap;
    height: 75vh;
    background: #F9F5F1;
    padding: 6px;
    border-radius: 2px;

    width: calc(100% - $container-padding*4);
    margin-left: $container-padding*2;
    margin-right: $container-padding*2;

    @media only screen and (max-width: $container-width) {
      width: calc(100% - $container-padding*2);
      margin-left: $container-padding;
      margin-right: $container-padding;
    }
  }
  .mapframe {
    width: 100%;
    height: 100%;
    padding: 0;
  }
  .stickie {
    font-family: 'Bradley Hand', 'Brush Script MT', 'Brush Script', 'Comic Sans MS', 'Comic Sans', cursive, serif;
    position: absolute;
    right: 40px;
    bottom: -40px;
    rotate: 20deg;
    font-size: 18px;
    width: 140px;
    aspect-ratio: 1/1;
    background: rgb(255, 244, 143);
    border-bottom-right-radius: 60px 10px;
    box-shadow: 0.5px 2px 2px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    a {
      color: #1D1C42;
      rotate: -33deg;
      white-space: nowrap;
    }
  }
}
.page-our-work-teachosm {
  .cover {
    background-image: url($baseurl + "/img/pages/teachosm/greyscale.jpg");
    background-size: cover;
    background-position: center;
  }
  a.button.prominent {
    background-color: #7FBC6F;
    border-color: #7FBC6F;
  }
}
.page-our-work-trails {
  h1 {
    font-family: "National Park";
    font-weight: 700;
  }
  .prose {
    h2 {
      font-family: "National Park";
      font-weight: 700;
    }
  }
  .themed {
    background-image: url($baseurl + "/img/pages/trails/bg.jpg");
    background-position: center bottom;
    background-size: cover;
    color: #3C2F24;

    &.banner-footer {
      background-position: center 56%;
      height: 70px;
    }
  }
  a.button.bordered {
    border-color: #3C2F24;
    color: #3C2F24;
  }   
  a.button.prominent {
    background-color: #3C2F24;
    border-color: #3C2F24;
    color: white;
  }
}
.ispage-our-work-trails {
  h1 {
    font-size: 2em;
  }
  .cover {
    height: calc(100vh - $topbar-height);
    max-height: 100vw;
    min-height: 650px;

    .pagenav {
      border-bottom-color: $alpha-grid-color;
    }
  }
}
.page-our-work-charter-projects-maproulette {
  .cover {
    background: linear-gradient(to right, #172E51, #53856E);
  }
  a.button.prominent {
    color: #172E51;
    background-color: rgba(145,243,162, 1);
    border-color: rgba(145,243,162, 1);
  }
}
.page-our-work-charter-projects-osmcha {
  .cover {
    background: linear-gradient(to right, #731323, #CC2C47);
  }
  .extra-1, .extra-2 {
    position: absolute;
    left: 0;
    width: 100%;
    height: 8px;
  }
  .extra-1 {
    bottom: 0;
    background: linear-gradient(to right, #31AB97, #3ADBC0);
  }
  .extra-2 {
    bottom: 8px;
    background: linear-gradient(to right, #b68421, #DB950A);
  }
  a.button.prominent {
    background-color: #61A5F5;
    border-color: #61A5F5;
  }
}

.ispage-events-osmgeoweek {
  .themed {
    background: #202020;
    color: white;

    a.button.bordered {
      color: #2EB05C;
      border-color: #2EB05C;
    }

    a.button.prominent {
      color: white;
      background-color: #2EB05C;
      border-color: #2EB05C;
    }
  }
}

.ispage-events-mapping-usa {
  .themed {
    background: #06121A;
    color: #E5F7FC;

    a.button.bordered {
      color: #E5F7FC;
      border-color: #E5F7FC;
    }

    a.button.prominent {
      color: #06121A;
      background-color: #E5F7FC;
      border-color: #E5F7FC;
    }
  }
}
.page-events-mapping-usa-2024,
.page-events-mapping-usa-2025 {

  .content {
    a:not(.button) {
      color: #1d5075;
    }
  }

  .countdown {
    border-top: 1px solid #06121a;
    border-bottom: 1px solid #06121a;
    color: #06121a;
    padding: 5px 10px;
  }

  .themed {
    color: #06121A;
    background-color: #E5F7FC;
    background-image: url($baseurl + "/img/pages/mapping-usa-2024/flakes.png");
    background-size: cover;
    background-position: center;
    
    img.wordmark {
      max-height: 100px;
      margin-top: 20px;
    }
    .pagenav {
      border-bottom: 0;
    }
    a.button.bordered {
      border-color: #06121A;
      color: #06121A;
    }
    a.button.prominent {
      color: white;
      background-color: #06121A;
      border-color: #06121A;
    }
  }

  table.schedule {
    th {
      color: white;
      background-color: #06121A;
      border-color: #232d34;
    }
  }
}
#notice-overlay.mapping-usa-2025 {
  color: #06121A;
  background-color: #E5F7FC;
}
.page-events-mapping-usa-2022 {
  .themed {
    color: #FFF9F3;
    background: #361100;
    a.button.prominent {
      background-color: #009016;
      border-color: #009016;
    }
  }
  .content {
    background: #FFF9F3;
    a:not(.button) {
      color: #361100;
    }
  }

  table.schedule {
    
    th {
      color: #FFF9F3;
      background-color: #361100;
      border-color: #4d3429;
    }

    tr {
      background: initial;
    }
  }
  
}
.page-events-mapping-usa-2021 {
  .themed {
    background: #FA5C5A;
  }
  table.schedule {
    
    th {
      color: white;
      background-color: #FA5C5A;
      border-color: #df534f;
    }
  }
}
.page-events-mapping-usa-2020 {
  .themed {
    background: #76B8CB;
  }
}

.page-events-mappy-hours {
  .themed {
    background: linear-gradient(to right, #AE222E, #2A0D41);

    a.button.prominent {
      background-color: #fff;
      border-color: #fff;
      color: #AE222E;
    }
  }
}

.page-events-state-of-the-map-us-2024 {

  h1, h2, h3, h4, h5, h6 {
    font-family: "National Park";
    font-weight: 700;
  }

  .custom-marker.main-venue {
    --color: #BD9836;
  }
  .custom-marker.transport {
    --color: #393B57;
  }
  .custom-marker.transport-minor {
    --color: #7C7E96;
  }
  .custom-marker.lodging {
    --color: #7f766e;
  }
  .layout-body .dark {
    background: #393B57;
    color: white;
    a:not(.button) {
      color: white;
      text-decoration: underline;
    }
  }
  .themed {
    background: url($baseurl + "/img/pages/sotmus/2024/contours.jpg");
    background-position: center;
  }
}

.ispage-events-state-of-the-map-us-2024,
.ispage-events-state-of-the-map-us-2025 {
  .pre-cover {
    width: 100%;
    background-position: center;
  }
  .cover {
    overflow: visible;

    .logo-area {
      position: relative;
      flex-direction: column;
      align-items: center;
      text-align: center;

      .logo-wrap {
        max-width: 100%;
        max-height: initial;
      }

      .right {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .blurb {
        font-size: 20px;
        margin-top: $sp;
      }
    }
    .pagenav, .tagline, .breadcrumbs {
      display: none;
    }
    .buttons {
      flex-direction: column;
      align-items: center !important;

      .text-buttons {
        margin-bottom: 14px;

        > .button {
          margin-left: 5px;
          margin-right: 5px;
          margin-bottom: 10px;
        }
      }
    }
  }
}

.ispage-events-state-of-the-map-us-2024 {
  .pre-cover {
    height: 50vw;
    max-height: 290px;
    background: url($baseurl + "/img/pages/sotmus/2024/contours.jpg");
  }
  .cover {
        
    max-height: 100vw;
    min-height: 800px;
    height: 100vh;
    padding-bottom: 40px;

    .logo-area{ 
      top: -270px;

      @media only screen and (max-width: 700px) {
        top: calc(-40vw - 20px);
      }
      .logo-wrap {
        width: 700px;
      }
    }
  }
  .buttons {
    a.button.prominent {
      background-color: #EAC25A;
      border-color: #EAC25A;
      color: #534F4C;
    }
  }
  .themed {

    background-image: url($baseurl + "/img/pages/sotmus/2024/skyline.jpg");
    background-position: center bottom;
    background-size: cover;
    color: white;

    &.banner-footer {
      background-position: center 56%;
      height: 70px;
    }
  }
}
.ispage-events-state-of-the-map-us-2025 {
  .pre-cover {
    height: 400px;
    filter: blur(0.75px);
    background: url($baseurl + "/img/pages/sotmus/2025/boston-skyline.jpg");
    background-position: center;
    background-size: cover;
  }
  .cover {
    .logo-area {
      .logo-wrap {
        position: absolute;
        width: 450px;
        top: -370px;
        margin-right: 0;
        @media only screen and (max-width: 470px) {
          top: calc(-75vw);
        }
      }
      .right {
        margin-top: 36px;
      }
    }
  }
}

.page-events-state-of-the-map-us {
  .image-gallery {
    margin-top: -$sp;
  }
  .image-band {
    display: flex;
    justify-content: center;
    overflow: hidden;
    img {
      border-radius: 2px;
      margin-right: $sp*0.25;
      margin-left: $sp*0.25;
      margin-bottom: $sp*0.5;
      max-width: 350px;
      width: 30vw;
    }
  }
}

.page-events-state-of-the-map-us-2025 {

  h2, h3, h4, h5, h6 {
    font-family: "Boston Angel", $serif-font;
    font-weight: 400;
  }

  h1 {
    font-family: "Boston Angel", $serif-font;
    font-size: 40px;
  }
  h2 {
    font-size: 34px;
  }
  h3 {
    text-transform: uppercase;
    font-size: 26px;
  }

  a.button.bordered {
    border-color: #CC0001;
    color: #CC0001;
  }
  a.button.prominent {
    background-color: #CC0001;
    border-color: #CC0001;
    color: #FFF8EC;
  }

  .dark {
    a.button.bordered.white {
      border-color: #fff;
      color: #fff;
    }
  }

  .themed {
    background: #FFF8EC;
    color: #273455;
    border-top: 10px solid #273455;
    border-bottom: 1px solid #DFC9A3;

    h1 {
      font-size: 3em;
      color: #CC0001;
    }
  }

  .layout-body {
    a {
      color: #CC0001; 
    }
    figcaption a {
      color: #A4D0D8;
    }
  }

  .custom-marker.main-venue {
    --color: #CC0001;
  }
  .custom-marker.transport {
    --color: #273455;
  }
  .custom-marker.transport-minor {
    --color: #7C7E96;
  }
  .custom-marker.lodging {
    --color: #b09568;
  }

  .layout-body .dark {
    background: #273455;
    color: white;
    a:not(.button) {
      color: white;
      text-decoration: underline;
    }
  }
}

.page-events-state-of-the-map-us-2012 {
  .themed {
    background: #172339;
    background-image: url($baseurl + "/img/pages/sotmus/2012/bg.png");
    background-position: 90% bottom;
    background-size: 400px;
    background-repeat: no-repeat;

    a.button.prominent {
      background-color: #94DD56;
      border-color: #94DD56;
      color: #172339;
    }
  }
}
.page-events-state-of-the-map-us-2013 {
  .themed {
    background: #72999B;

    a.button.prominent {
      background-color: #B25E4B;
      border-color: #B25E4B;
    }
  }
}

.page-events-state-of-the-map-us-2014 {
  .themed {
    background: #31b2d0;
    background-image: url($baseurl + "/img/pages/sotmus/2014/bg.png");
    background-position: 90% bottom;
    background-size: 400px;
    background-repeat: no-repeat;

    a.button.prominent {
      background-color: #0F2D34;
      border-color: #0F2D34;
    }
  }
}
.page-events-state-of-the-map-us-2015 {
  .themed {
    background: #1b1b1b;
    background-image: url($baseurl + "/img/pages/sotmus/2015/bg.png");
    background-position: 90% 92%;
    background-size: 200px;
    background-repeat: no-repeat;

    a.button.prominent {
      background-color: #53104C;
      border-color: #53104C;
    }
  }
}
.page-events-state-of-the-map-us-2016 {
  .themed {
    background-color: #142736;
    background-image: url($baseurl + "/img/pages/sotmus/2016/bg.jpg");
    background-position: center bottom;
    background-size: cover;

    a.button.prominent {
      background-color: #cfdcf3;
      border-color: #cfdcf3;
      color: #142736;
    }
  }
}
.page-events-state-of-the-map-us-2017 {
  .themed {
    background-color: #283277;
    background-image: url($baseurl + "/img/pages/sotmus/2017/bg.png");
    background-position: right bottom;
    background-size: 600px;
    background-repeat: no-repeat;

    a.button.prominent {
      background-color: #D22143;
      border-color: #D22143;
    }
  }
}
.page-events-state-of-the-map-us-2018 {
  .themed {
    background-color: white;
    background-image: url($baseurl + "/img/pages/sotmus/2018/bg.png");
    background-position: center bottom;
    background-size: 1300px;
    background-repeat: no-repeat;
    color: #006aaf;

    a.button.prominent {
      background-color: #006aaf;
      border-color: #006aaf;
      color: white;
    }
  }
}
.page-events-state-of-the-map-us-2019 {
  .themed {
    background: #5F3930;

    a.button.prominent {
      background-color: #89C5D3;
      border-color: #89C5D3;
    }
  }
}

.page-events-state-of-the-map-us-2022 {
  .themed {
    background-color: #5D9A79;
    background-image: url($baseurl + "/img/pages/sotmus/2022/bg.png");
    background-position: center bottom;
    background-size: 1000px;
    background-repeat: repeat-x;
    padding-bottom: $sp*2.5;

    a.button.prominent {
      background-color: #DDAB6F;
      border-color: #DDAB6F;
      color: #365254;
    }
  }    
}
.page-events-state-of-the-map-us-2023 {
  .themed {
    background: #253423;
    background-image: url($baseurl + "/img/pages/sotmus/2023/bg.png");
    background-position: center bottom;
    background-size: 700px;
    background-repeat: repeat-x;
    padding-bottom: $sp*2.5;

    a.button.prominent {
      background-color: #965354;
      border-color: #965354;
    }   
  }
}
.page-news-newsletter {
  h1 {
    font-family: 'Courier Prime', 'Courier New', Courier, monospace;
  }
}

.swag-list.item-list.gallery {
  .item-listing {
    width: calc($content-width * 0.333);
  }
}